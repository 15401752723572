.Container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Bar {
    width: 100%;
    padding: 10px;
    background-color: rgb(172, 182, 207, 0.5);

    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Logo {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Logo-text {
    margin-left: 5px;

    font-family: Tahoma;
    font-weight: 900;
    font-size: 26px;

    color: #2b354f;
}

.Menu {
    flex: 3;
    display: flex;
    flex-direction: row;

    margin-left: 80px;
}

@media (max-width: 1000px) {
    .Menu {
        margin-left: 20px;
    }
}

.Menu-option {
    margin: 15px;
    padding: 10px;
    width: 80px;

    border-radius: 10px;

    font-family: Tahoma;
    font-weight: 700;
    font-size: 18px;

    cursor: pointer;

    text-align: center;

    background-color: rgb(172, 182, 207, 0.7);
}

.Menu-option:hover {
    background-color: rgb(172, 182, 207, 0.9);
}

.OrderSettingsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Order {
    font-weight: 700;
    font-size: 18px;
    background-color: rgb(210, 244, 255, 0.5);

    text-align: center;
    margin: 15px;
    padding: 10px;
    border-radius: 10px;
}

.Order:hover {
    background-color: rgb(210, 244, 255, 0.8);
    cursor: pointer;
}

.Settings {
    margin-right: 15px;
    transition: rotate 2s;
    cursor: pointer;
}

.Settings:hover {
    rotate: 180deg;
}