.Container {
    display: flex;
    flex-direction: column;
}

.Title {
    font-weight: 900;
    font-size: 22px;

    margin-bottom: 10px;
}

.TimelinePanel {
    border-radius: 15px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);

    background-color: rgb(192, 214, 223);
    width: 95%;
    height: 35.15vh;

    overflow-y: scroll
}

@media (min-width: 1280px)  {
    .TimelinePanel {
        height: 49.5vh;
    }
}

.Entry {
    display: flex;
    flex-direction: column;

    margin-top: 10px;
    margin-bottom: 10px;
}

.Entry-Container {
    width: 90%;

    background-color: rgb(232, 218, 178);
    border-radius: 10px;
    padding: 10px;

    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.Entry-Title {
    font-weight: 900;
}

.Entry-ContentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Entry-Content {
    flex: 3;
    white-space: pre-line;
}

.Entry-IconContainer {
    display: flex;
    justify-content: flex-end;
}

.Entry-Icon {
    max-width:70%;
    object-fit: contain;
}
