.App {
  width: 100vw;
  height: 100vh;

  background-color: #EAEAEA;
  font-family: Tahoma;
}

.MainContainer {
  display: flex;
  flex-direction: row;

  margin-top: 20px;
}

.LeftContainer {
  flex: 1;
  width: 100%;
}

.RightContainer {
  flex: 1;
  width: 100%;
}

.ChildContainer {
  margin-bottom: 30px;
}