.Container {
    display: flex;
    flex-direction: column;
}

.Title {
    font-weight: 900;
    font-size: 22px;

    margin-bottom: 10px;
}

.StatsPanel {
    border-radius: 15px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);

    background-color: rgb(192, 214, 223);
    width: 95%;
    height: 15vh;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

@media (min-width: 1280px) {
    .StatsPanel {
        height: 21vh;
    }
}

.Stat {
    font-family: Tahoma;
    text-align: center;
    margin: 5px;
    margin-top: 20px;
    height: 90%;
}

.Stat-Title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 18px;
    white-space: nowrap;
}

.Stat-Value {
    font-weight: 900;
    font-size: 20px;
    margin-top: -70px;
}

.Stat-Value--unit {
    font-size: 10px;
}

.Stat-Insight {
    margin-top: 20px;
    font-weight: 500;
    font-size: 12px;
    color: rgb(43, 53, 79, 0.7);
}

.CircularSlider-BarSVG {
    stroke-width: 8px;
    stroke-linecap: round;
    fill: none;
}

.CircularSlider-TickSVG {
    stroke: black;
    stroke-width: 2px;
    fill: none;
}