.Container {
    display: flex;
    flex-direction: column;
}

.Title {
    font-weight: 900;
    font-size: 22px;

    margin-bottom: 10px;
}

.HeatmapMap {
    width: 95%;
    height: 20vh;
}

@media (min-width: 1280px) {
    .HeatmapMap {
        height: 30vh;
    }
}